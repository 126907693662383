import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import TermsModal from "../modals/TermsModal";
import { Modal, ModalProps } from "../Orichal";
import ContactModal from "../modals/ContactModal";
import { useState } from "react";
import TokenAdvisory from "../modals/TokenAdvisory";

type Props = {
  modal: ModalProps;
  setModal: React.Dispatch<React.SetStateAction<ModalProps>>;
  setSeenInvestmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSeenAdvisoryModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const Header = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const { modal, setModal, setSeenInvestmentModal, setSeenAdvisoryModal } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileModalMenuOpen, setMobileModalMenuOpen] = useState<boolean>(false);
  const handleOk = () => {
    window.location.href = "https://opm.trading";
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <div className="header-wrapper">
      <div
        className={"mask" + (modal.state !== Modal.None ? " visible" : "")}
        // onClick={() => modal.state !== Modal.None && setModal({ state: Modal.None })}
        //force user to click "I Agree"
      />
      {modal.state === Modal.InvestmentTerms || modal.state === Modal.AdvisoryTerms ? (
        <TermsModal
          modalState={modal.state}
          setModal={setModal}
          setSeenInvestmentModal={setSeenInvestmentModal}
          setSeenAdvisoryModal={setSeenAdvisoryModal}
        />
      ) : null}
      {modal.state === Modal.Contact ? <ContactModal setModal={setModal} /> : null}
      <div className="desktop-left">
        <div className="orichal-logo" onClick={() => navigate("/")} />
        <Link className="link" to={"/"} data-selected={location.pathname === "/"}>
          HOME
        </Link>
        <Link className="link" to={"/investment"} data-selected={location.pathname === "/investment"}>
          INVESTMENT
        </Link>
        {/* <Link className="link" to={"/advisory"} data-selected={location.pathname === "/advisory"}>
          TOKEN ADVISORY
        </Link> */}
        <div className="token-advisory" onClick={()=>setVisible(true)}  data-selected={location.pathname === "/advisory"}>
          TOKEN ADVISORY
        </div>
        <Link className="link" to={"/insights"} data-selected={location.pathname === "/insights"}>
          INSIGHTS
        </Link>
        <Link className="link" to={"/team"} data-selected={location.pathname === "/team"}>
          ABOUT US
        </Link>
      </div>
      <div className="mobile-left">
        <div className="orichal-logo" onClick={() => navigate("/")} />
      </div>
      <div className="desktop-right">
        {/* <p>INVESTOR LOGIN</p> */}
        <button onClick={() => setModal({ state: Modal.Contact })}>SPEAK WITH US</button>
      </div>
      <div className="mobile-right">
        <div
          className={"burger" + (mobileModalMenuOpen ? " ex" : "")}
          onClick={() => setMobileModalMenuOpen(!mobileModalMenuOpen)}
        >
          <div className="burger-bar top" />
          <div className="burger-bar mid" />
          <div className="burger-bar bot" />
        </div>
      </div>
      <div className={"mobile-modal-menu" + (mobileModalMenuOpen ? " open" : "")}>
        <div className="main-menu">
          <Link to="/" style={{ textDecoration: "none" }} onClick={() => setMobileModalMenuOpen(false)}>
            HOME
          </Link>
          <Link to="/investment" style={{ textDecoration: "none" }} onClick={() => setMobileModalMenuOpen(false)}>
            INVESTMENTS
          </Link>
          {/* <Link to="/advisory" style={{ textDecoration: "none" }} onClick={() => setMobileModalMenuOpen(false)}>
            TOKEN ADVISORY
          </Link> */}
           <div className="token-advisory" onClick={()=>setVisible(true)}  data-selected={location.pathname === "/advisory"}>
          TOKEN ADVISORY
        </div>
          <Link to="/insights" style={{ textDecoration: "none" }} onClick={() => setMobileModalMenuOpen(false)}>
            INSIGHTS
          </Link>
          <Link to="/team" style={{ textDecoration: "none" }} onClick={() => setMobileModalMenuOpen(false)}>
            ABOUT US
          </Link>
        </div>
        <div className="buttons">
          {/* <button>INVESTOR LOGIN</button> */}
          <button
            onClick={() => {
              setMobileModalMenuOpen(false);
              setModal({ state: Modal.Contact });
            }}
          >
            SPEAK WITH US
          </button>
        </div>
      </div>
        {/* 跳转提示弹窗 */}
        <TokenAdvisory
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default Header;
